<template>
  <v-row align="center">
    <v-col
      cols="12"
      md="auto">
      <h3>
        แพ็กเกจทัวร์
      </h3>
    </v-col>
    <v-spacer />
    <v-col
      cols="12"
      md="4">
      <SearchBox
        v-model="query.search"
        :disabled="loading"
        @search="getItems(true)" />
    </v-col>
    <v-col
      cols="12"
      md="auto">
      <tour-filters v-model="query" />
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        item-key="packages.startDate"
        :sort-by.sync="query.sortBy"
        :sort-desc.sync="query.sortOrder"
        hide-default-footer
        class="table-border">
        <template #[`item.packagesStartDate`]="{ item }">
          {{ item.packages.startDate | dateFormat() }} - {{ item.packages.endDate | dateFormat() }}
        </template>
        <template #[`item.orderAmount`]="{ item }">
          {{ (item.orderAmount || 0) | showNumberFormat() }}
        </template>
        <template #[`item.orderSales`]="{ item }">
          ฿ {{ (item.orderSales || 0) | showNumberFormat() }}
        </template>
        <!-- <template #[`item.status`]="{ item }">
          <v-switch
            v-model="item.status"
            color="success"
            true-value="available"
            false-value="unavailable"
            inset
            hide-details
            readonly
            class="mt-0 pt-0"
            @click="confirmStatusToggle(item)" />
        </template> -->
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
            offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                color="secondary"
                icon
                v-bind="attrs"
                v-on="on">
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <!-- <v-list-item
                :ripple="false"
                @click="detailItem(item)">
                <v-list-item-title>
                  ดูข้อมูล
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item
                :ripple="false"
                @click="editItem(item)">
                <v-list-item-title>
                  แก้ไข
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :ripple="false"
                @click="confirmDialogToggle(item)">
                <v-list-item-title>
                  ลบ
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <div class="d-flex justify-end">
        <v-pagination
          v-model="query.page"
          :length="totalPages"
          :total-visible="7"
          circle
          color="primary" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import ToursProvider from '@/resources/tours.provider'
import ReportsProvider from '@/resources/reports.provider'
import TourFilters from '@/components/TourFilters.vue'

const ToursService = new ToursProvider()
const ReportsService = new ReportsProvider()

export default {
  components: {
    SearchBox,
    TourFilters
  },
  props: {
    vendorId: {
      type: [String, Number],
      default: null
    }
  },
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'รอบการเดินทาง',
        value: 'packagesStartDate',
        class: 'table-header',
        align: 'center',
        width: '200'
      },
      {
        text: 'เลขที่แพ็กเกจทัวร์',
        value: 'id',
        class: 'table-header',
        align: 'center',
        sortable: false,
        width: '180'
      },
      {
        text: 'ชื่อแพ็กเกจทัวร์',
        value: 'name',
        class: 'table-header',
        cellClass: 'limit-text',
        sortable: false
      },
      {
        text: 'ยอดการจองทัวร์',
        value: 'orderAmount',
        class: 'table-header',
        align: 'center',
        width: '180'
      },
      {
        text: 'ยอดการขายรวม',
        value: 'orderSales',
        class: 'table-header',
        align: 'right',
        width: '180'
      },
      // {
      //   text: 'สถานะ',
      //   value: 'status',
      //   class: 'table-header',
      //   sortable: false
      // },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right'
      }
    ],
    items: [],
    query: {
      sortBy: '',
      sortOrder: true,
      page: 1,
      limit: 10,
      search: '',
      continents: [],
      countries: [],
      vendorId: null,
      status: null,
      startDate: '',
      endDate: ''
    },
    totalPages: 1
  }),
  watch: {
    'query.sortBy': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.sortOrder': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    },
    'query.continents': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.countries': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.vendorId': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.status': {
      handler () {
        this.getItems(true)
      },
      deep: true
    }
  },
  mounted () {
    this.getItems()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    changeRoute (to) {
      this.$router.push({ name: to })
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        // const { data } = await ToursService.getItems({
        //   ...this.query,
        //   vendorId: this.vendorId
        // })
        const sortBy = this.query.sortBy && this.query.sortBy === 'packagesStartDate' ? 'packages.startDate' : ''
        const { data } = await ReportsService.getSalesReportByPackages({
          ...this.query,
          sortBy: sortBy || this.query.sortBy || 'createdAt',
          sortOrder: this.query.sortOrder || !this.query.sortBy ? 'desc' : 'asc',
          vendorId: this.vendorId
        })

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onRemove (id) {
      try {
        this.loading = true

        await ToursService.deleteItemById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'ลบข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onRemove', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onUpdateStatus (id) {
      try {
        this.loading = true

        await ToursService.updateItemStatusById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'แก้ไขข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onUpdateStatus', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    detailItem (item) {
      this.$router.push({
        name: 'TourDetail',
        params: {
          id: item.id
        }
      })
    },
    editItem (item) {
      this.$router.push({
        name: 'TourEdit',
        params: {
          id: item.id
        }
      })
    },
    confirmDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะลบ "${item.name}" ใช่หรือไม่?`,
        onConfirm: () => this.onRemove(item.id)
      })
    },
    confirmStatusToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะ${item.status === 'available' ? 'ปิด' : 'เปิด'}การขายแพ็กเกจ "${item.id}" ใช่หรือไม่?`,
        onConfirm: () => this.onUpdateStatus(item.id)
      })
    }
  }
}
</script>

<style scoped>
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
</style>
